.chart-container {
  background-color: #F9ECF9;
  padding: 20px; /* Default padding */
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

@media (max-width: 600px) {
  .chart-container {
    padding: 0; /* No padding on mobile */
  }
}